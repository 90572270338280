<template>
    <v-layout wrap v-if="data">
        <v-flex xs12 sm6>
            <v-card color="success">
                <v-card-text class="white--text">
                    <div class="headline mb-2">
                        <v-icon dark>place</v-icon>
                        {{ total() }}
                    </div>
                    Pontos
                </v-card-text>
            </v-card>
        </v-flex>
        <v-flex xs12 sm6>
            <v-card color="success">
                <v-card-text class="white--text">
                    <div class="headline mb-2">
                        <v-icon dark>functions</v-icon>
                        {{ chargers() }}
                    </div>
                    Carregadores
                </v-card-text>
            </v-card>
        </v-flex>
        <v-flex xs6 sm3 v-if="offline > 0">
            <v-card color="black">
                <v-card-text class="white--text">
                    <div class="headline mb-2">
                        <v-icon dark>functions</v-icon>
                        {{ offline }}
                    </div>
                    Plugs Offline
                </v-card-text>
            </v-card>
        </v-flex>
        <v-flex xs6 sm3 v-if="available > 0">
            <v-card color="success">
                <v-card-text class="white--text">
                    <div class="headline mb-2">
                        <v-icon dark>functions</v-icon>
                        {{ available }}
                    </div>
                    Plugs Disponíveis
                </v-card-text>
            </v-card>
        </v-flex>
        <v-flex xs6 sm3 v-if="charging > 0">
            <v-card color="primary">
                <v-card-text class="white--text">
                    <div class="headline mb-2">
                        <v-icon dark>functions</v-icon>
                        {{ charging }}
                    </div>
                    Plugs Carregando
                </v-card-text>
            </v-card>
        </v-flex>
        <v-flex xs6 sm3 v-if="connected > 0">
            <v-card color="warning">
                <v-card-text class="secondary--text">
                    <div class="headline mb-2">
                        <v-icon color="secondary">functions</v-icon>
                        {{ connected }}
                    </div>
                    Plugs Ocupados
                </v-card-text>
            </v-card>
        </v-flex>

        <v-flex xs12>
            <div class="ma-4 text-center" v-if="loaded == false">
                <v-progress-circular size="140" width="20" color="primary" indeterminate />
            </div>
            <v-progress-linear progress color="primary" :value="timerProgress" v-else />
        </v-flex>

        <v-flex xs12 sm6 md4 text-center class="my-0 py-0">
            <v-autocomplete v-model="filter.model" :items="models" item-value="id" item-text="name" label="Modelo" deletable-chips small-chips @change="reload" />
        </v-flex>
        <v-flex xs12 sm6 md4 text-center class="my-0 py-0">
            <v-autocomplete v-model="filter.protocol" :items="protocols" item-value="id" item-text="name" label="Protocolo" deletable-chips small-chips @change="reload" />
        </v-flex>
        <v-flex xs12 md4 class="my-0 py-0">
            <ListClient :value="filter.clients" @change="changeClients" :multiple="true" />
            <!-- <v-text-field label="Buscar" v-model="filter.text" clearable hide-details /> -->
        </v-flex>

        <v-flex xs12 text-center>
            <v-btn-toggle variant="elevated" color="primary" class="ma-0 pa-0" v-model="filter.mode" mandatory divided @change="reload">
                <v-btn small value="DASHBOARD">
                    Dashboard
                    <v-icon right color="primary">
                        dashboard
                    </v-icon>
                </v-btn>
                <v-btn small value="COMPACT">
                    Lista
                    <v-icon right color="primary">
                        list
                    </v-icon>
                </v-btn>
            </v-btn-toggle>
        </v-flex>

        <v-flex v-if="filter.mode == 'COMPACT'" xs12 class="ma-0 py-0">
            <v-list-item-group v-for="(item, i) in listWithFilter" :key="i">
                <v-list-item dense @click="changeRoute(`/charger/${item.charger}`)" :class="itemClass(item.charger.status)">
                    <v-list-item-subtitle>{{ item.point }} '{{ item.charger }}' </v-list-item-subtitle>
                    <v-list-item-subtitle>
                        <strong v-for="(plug, w) in item.plugs" :key="w" class="mx-8"> Plug: {{ w + 1 }}:{{ plug }}</strong>
                    </v-list-item-subtitle>
                </v-list-item>
                <v-divider />
            </v-list-item-group>
        </v-flex>

        <v-flex v-else xs12 class="ma-0 py-0">
            <v-layout wrap class="ma-0 pa-0">
                <v-flex xs12 sm6 md4 lg3 xl2 v-for="(item, i) in listWithFilter" :key="i">
                    <v-alert border="left" class="my-1" colored-border color="primary accent-4" elevation="12">
                        <div class="headline mb-2 primary--text">
                            {{ item.point }}
                        </div>
                        <v-layout wrap class="ma-0 pa-0">
                            <v-flex xs12 class="ma-0 pa-0 pb-1" v-for="(listCharger, k) in item.chargers" :key="k">
                                <v-alert :border="k != '-' ? 'left' : null" class="my-0" colored-border color="primary accent-4" elevation="4">
                                    <v-layout wrap class="ma-0 pa-0">
                                        <v-flex xs12 class="ma-0 pa-0" v-if="k != '-'">
                                            <v-btn x-small color="grey" style="width: 100%" :href="href(`/board/${k}`)" target="_blank" text>
                                                Gateway: {{ k }}
                                            </v-btn>
                                        </v-flex>
                                        <v-flex xs12 class="ma-0 pa-0" v-for="(charger, j) in listCharger" :key="j">
                                            <v-btn x-small color="primary" class="mt-6 mb-2" style="width: 100%" :href="href(`/charger/${charger.name}`)" target="_blank" text>
                                                {{ charger.name }}
                                                <v-spacer />
                                                <v-icon dark small>settings</v-icon>
                                            </v-btn>
                                            <v-btn x-small :class="btnClass(plug)" style="width: 100%" :href="href(`/now/${charger.name}/${w + 1}`)" target="_blank"
                                                v-for="(plug, w) in charger.plugs" :key="w">
                                                Plug {{ w + 1 }}
                                                <v-spacer />
                                                {{ plug }}
                                            </v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-alert>
                            </v-flex>
                        </v-layout>
                    </v-alert>
                </v-flex>
            </v-layout>
        </v-flex>
    </v-layout>
</template>

<script>
import statusCharger from "@/helpers/StatusCharger";
import protocols from "@/assets/json/protocols.json";
import models from "@/assets/json/models.json";

import ListClient from "@/components/shared/ListClient";

export default {
    name: "Charger",

    components: { ListClient },

    data() {
        return {
            statusCharger,
            data: [],
            loaded: false,
            qualities: [],
            filter: {
                mode: "DASHBOARD",
                text: "",
                model: null,
                protocol: null,
                clients: null
            },
            offline: 0,
            available: 0,
            charging: 0,
            connected: 0,
            timer: null,
            timerProgress: 100,
            models,
            protocols,
        };
    },

    computed: {
        listWithFilter() {
            if (this.filter.text && this.data) {
                let exp = new RegExp(this.filter.text.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, ""), "i");
                return this.data.filter((i) => (exp.test(`${i.point}${i.charger}`.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))));
            } else {
                return this.data;
            }
        },
    },

    created() {
        if (localStorage.getItem("filter_v2")) {
            this.filter = JSON.parse(localStorage.getItem("filter_v2"));
        }
        this.update();
        this.timer = setInterval(this.timerTick, 300);

    },

    methods: {
        changeClients(newClients) {
            if (newClients && newClients.length > 0) {
                this.filter.clients = newClients;//.map((i) => i && i.id ? i.id : undefined);
            } else {
                this.filter.clients = null;
            }
            this.reload();
        },
        href(url) {
            return `${window.location.origin}${url}`;
        },
        reload() {
            this.data = [];
            this.qualities = [];
            this.update();
            localStorage.setItem("filter_v2", JSON.stringify(this.filter));
        },
        changeRoute(route) {
            localStorage.setItem("filter_v2", JSON.stringify(this.filter));
            this.$router.push({ path: route })
        },
        timerTick() {
            if (this.timerProgress > 0) {
                this.timerProgress--;
            } else {
                this.update();
                this.timerProgress = 100;
            }
        },
        btnClass(status) {
            const color = this.statusCharger.getComponentStyle(status);
            return `${color.color} ${color.secondColor}--text`;
        },
        itemClass(status) {
            switch (status) {
                case "Available":
                    return "success lighten-4"
                case "Charging":
                    return "primary lighten-4"
                case "Connected":
                    return "warning lighten-4"
                case "Offline":
                    return "black theme--dark"
                default:
                    return ""
            }
        },
        total() {
            if (this.filter.mode == "COMPACT") {
                return Number.parseFloat((new Set(this.data.map((item) => item.point))).size).toFixed(0);
            } else {
                return Number.parseFloat(this.data.length).toFixed(0);
            }
        },
        chargers() {
            if (this.filter.mode == "COMPACT") {
                return Number.parseFloat(this.data.length).toFixed(0);
            } else {
                return this.qualities.length;
            }
        },
        updateCounters() {
            this.qualities = [];
            if (this.data && this.data.length > 0) {
                if (this.filter.mode == "COMPACT") {
                    for (let i = 0; i < this.data.length; i++) {
                        this.qualities.push(...this.data[i].plugs);
                    }
                } else {
                    for (let i = 0; i < this.data.length; i++) {
                        for (const [, boadChargers] of Object.entries(this.data[i].chargers)) {
                            for (let z = 0; z < boadChargers.length; z++) {
                                this.qualities.push(...boadChargers[z].plugs);
                            }
                        }
                    }
                }
                this.offline = this.qualities.filter(i => i == "Faulted").length;
                this.available = this.qualities.filter(i => i == "Available").length;
                this.charging = this.qualities.filter(i => i == "Charging").length;
                this.connected = this.qualities.filter(i => i == !i.includes(["Faulted", "Available", "Charging"])).length;
            }
        },
        async update() {
            let queryParameter = `?mode=${this.filter.mode}`;

            if (this.filter.model) {
                queryParameter = `${queryParameter}&model=${this.filter.model}`;
            }
            if (this.filter.protocol) {
                queryParameter = `${queryParameter}&protocol=${this.filter.protocol}`;
            }
            if (this.filter.clients) {
                queryParameter = `${queryParameter}&clients=${this.filter.clients}`;
            }

            await this.$http
                .get(`api/v3/points${queryParameter}`, { showLoading: false })
                .then((result) => {
                    this.data = result;
                    this.loaded = true;
                    this.updateCounters();
                })
                .catch(() => {
                    this.$eventHub.$emit("msgError", "Erro ao buscar informações.");
                });
        },
    },

    beforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>
